




import Vue from 'vue'
import AuthView from '~/components/context/auth/AuthView.vue'

export default Vue.extend({
	name: 'AuthLogin',
	components: { AuthView },
	layout: 'auth',
})
